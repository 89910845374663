import './App.css';
import Nav from './components/Nav/Nav'
import mainPic from "./assets/images/construction.jpg";
import OurMission from "./components/OurMission/OurMission"
import logo from './larid_logo.PNG';
import ContactForm from './components/ContactForm/ContactForm';


function App() {
  
  return (
    <div className="App">
    <Nav/>
    <img className="mobileLogo" src={logo} alt="" />
    <div className="container">
     
      <div className="first">
        <h1 className="mainTitle">LARID</h1>
        <div>
        <h1 className="definition">Lebanese Association for Research,
        <br/> Innovation and Development (LARID).</h1>
        
         <h2 className="definition">Non-Governmental Organization (NGO) </h2>
         
         <h2 className="definition"> Non-Profit Organization (NPO)</h2>
         </div>
        </div>
        <div className="second">
        <img className="pic1" src={mainPic} alt=""></img>
      </div>
      </div>
      <OurMission/>
      <ContactForm/>
      <footer className="footer">
        <img id="footer-logo" src={logo} alt="" />
        <div className="footer-info">
          
            <address>Address: LARID, Museum street, P.O. Box: 50-110 Beirut, Lebanon</address>
           <p className="copyright">
             <span dangerouslySetInnerHTML={{ "__html": "&copy;" }}></span>
             2021 LARID. All rights reserved
           </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
