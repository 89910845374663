import './ContactForm.css';
const ContactForm = () => {

  return (
    <div className="contactUs">
    <div className="contact-form">
      <h4 className="__title">CONTACT US</h4>
      <p className="__text">
        Your email address will not be published.
      </p>
     
      <form name="contact" method="POST" data-netlify="true" className="__form" >
        <div className="__input ">
        <input type="hidden" name="form-name" value="contact" />
          <input
            className="__input-field"
            name="name"
            type="text"
            placeholder="your name"
          />
          <input
            className="__input-field"
            name="email"
            type="email"
            placeholder="your email"
          />
        </div>
        <div className="__message">
          <textarea
            name="message"
            rows="10"
            className="__message"
            placeholder="your message"
          ></textarea>
        </div>
        <button type="submit" className="btn">
          SUBMIT
        </button>
      </form>
    </div>
    </div>
  );
};

export default ContactForm;
