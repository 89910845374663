import logo from '../../larid_logo.PNG';
import './Nav.css';
import linkedin from "../../assets/socialMediaIcons/linkedin.svg"

function Nav() {
  return (
    <div className="Nav">
      
       
        <div className="logoCon">
        <h1 className="title">LARID.ORG</h1>
     <img className="navLogo" src={logo} alt=""/>
     </div>
     
     <a href="https://www.linkedin.com/company/laridngo/"><img className="linkedin" src= {linkedin}  alt=""/></a>
     
    </div>
  );
}

export default Nav;
