import React , {useState , useEffect , useRef} from 'react';
import './OurMission.css';
import slide1 from "./images/slide1.jpg"
import slide2 from "./images/slide2.jpg"
import slide3 from "./images/slide3.jpg"
import slide4 from "./images/slide4.jpg"
import slide5 from "./images/slide5.jpg"
import slide6 from "./images/slide6.jpg"
import slide7 from "./images/slide7.jpg"
import slide8 from "./images/slide8.jpg"

function OurMission() {

   const [image , setImage] = useState(slide1);
   const [count , setCount] = useState(0)

  const images = [slide1 , slide2 , slide3 , slide4 , slide5 , slide6 , slide7 , slide8];

  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  
  useInterval(()=>{
   
   setImage(images[count])
   setCount(count+1)
   
   if(count===7){
     setCount(0);
   }
  },6000)

   

  return (
    <div className="ourMission">
      
       <div className="missionContainer">
           <div className="slideShow">
     
               <img className="slide" src={image} alt=""/>
               
           </div>
           <div className="missions">
           <h2 className="missionTitle">Our Missions</h2>
               <h4 className="missionsListTitle">As published in the Lebanese Official Gazette our missions are
                    to support the Research, Innovation and Development through:
               </h4>
               <ul className="missionsList">
                <li>Ensuring a framework that helps meet the research needs and activities.</li>
                <li>Establishing relationships with local and foreign organizations,
                     institutions and universities that support Research, Innovation and Development.</li>
                <li>Providing opportunities for students to pursue higher education in Lebanon and abroad.</li>
                <li>Organizing conferences and workshops provided by local and international specialists.</li>
                <li>Continuous and skills development.</li>

               </ul> 
           </div>
       </div>
    </div>
  );
}

export default OurMission;
